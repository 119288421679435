<app-breadcrumb [title]="curentLang === 'en' ? 'Invoices Info' : (curentLang === 'ar' ? ' معلومات الفواتير  ' : '   מידע על החשבוניות')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Invoices Info' : (curentLang === 'ar' ? ' معلومات الفواتير  ' : '   מידע על החשבוניות  ')">
</app-breadcrumb>

<!--invoices info -->

<div class="container-fluid basic_table">
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Invoices Info' : (curentLang === 'ar' ? '  معلومات الفواتير ' : '  מידע על החשבוניות  ')}}
        </h5>
           
       <div>
        <button [hidden]="!isValidMerchant" class="btn" style=" background-color: #141414; color: #fff;" 
        routerLink="/dashboard/add-invoice">
          <i class="fa fa-plus-circle mx-1"></i>
        {{ curentLang === 'en' ? 'Add Invoice' : (curentLang === 'ar' ? '  اضافة فاتورة  ' : 'הוספת חשובנית מס')}}
        
      </button>
 
      <!-- <button class="btn mx-1 btn-outline-warning"  >
        <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? 'تحديث' : 'רענן')}}
      </button> -->
      
       </div>
     
      </div>

      <div class="container-fluid basic_table" >
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group search px-1">
              <label for="seaarch" class="ml-2" style="color: grey;">
                {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
              </label>
              <div class="input-group">
                <input class="form-control w-100" type="text" name="search"
                       [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term"
                       placeholder="{{curentLang === 'en' ? 'Search by customer name or document number ' : (curentLang === 'ar' ? 'بحث بأسم العميل او رقم المستند' : 'חפש לפי שם הלקוח או מספר המסמך')}}"
                       />
                <!-- <div class="input-group-append m-auto">
                  <button class="btn" style="background-color: #141414; color: #fff;" type="button"
                          (click)="getInvoicesFilters(fromDate, toDate, limit, offset, undefined, term)">
                    <i class="fa fa-search mx-1"></i>
                    {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
                  </button>
                  <button class="btn btn-outline-secondary" type="button" (click)="term='';getInvoicesFilters(fromDate, toDate, limit, offset, undefined, term)">
                    <i class="fa fa-times"></i>
                  </button>
                </div> -->
              </div>
            </div>
          </div>
         
          <div class="col-sm-3 mt-2" >
            <label class="form-label px-1" style="color: grey;">
              {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? 'اسم الشركة' : '  שם החברה  ') }}
            </label>
            <ng-select [(ngModel)]="selectedCompany" (change)="filter($event)" class="px-1"
                       style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
              <ng-option [value]="allCompaniesOption">
                {{ curentLang === 'en' ? 'All Companies' : (curentLang === 'ar' ? 'الكل' : 'הכל') }}
              </ng-option>
              <ng-option *ngFor="let item of companyData" [value]="item">
                {{ curentLang === 'en' ? item?.company_name_en : (curentLang === 'ar' ? item?.company_name_ar : item?.company_name_he)}}
              </ng-option>
            </ng-select>
            <ng-template *ngIf="companyData.length === 0" class="px-1">
              <span style="color: red;">
                {{ curentLang === 'en' ? 'There are no invoices for this company yet' : (curentLang === 'ar' ? "لا توجد فواتير لهذه الشركة حتى الآن" : "אין עדיין חשבוניות לחברה זו")}}
              </span>
            </ng-template>
          </div>
         
          <form [formGroup]="documentForm"   >
  
        
  <div class="row d-flex justify-content-between ">
    <div class="col-md-3 mt-2 "  >
      <h6 class="form-label px-1 " style="color: grey;"> 
        {{ curentLang === 'en' ? 'Period' : (curentLang === 'ar' ? 'الفترة' : '  תקופה ')}}
      </h6>
      <div class="px-1">
        <select class="form-control  px-1  form-select" style="cursor: pointer;" (change)="filterMonth($event)" (click)="confirmAction()">
          <option [value]="1">{{ curentLang === 'en' ? 'Current month' : (curentLang === 'ar' ? 'الشهر الحالي' : 'חודש נוכחי  ') }}</option>
          <option [value]="2">{{ curentLang === 'en' ? 'Last month' : (curentLang === 'ar' ? 'الشهر الماضي' : '  חודש קודם') }}</option>
          <option [value]="5">{{ curentLang === 'en' ? 'Last two month' : (curentLang === 'ar' ? ' أخر شهران ' : ' שני חודשים קודמים  ') }}</option>
          <option [value]="3">{{ curentLang === 'en' ? 'Start of the year' : (curentLang === 'ar' ? 'من بداية العام' : ' מתחילת שנה  ') }}</option>
          <option [value]="4">{{ curentLang === 'en' ? 'Last year' : (curentLang === 'ar' ? 'العام الماضي' : ' שנה קודמת  ') }}</option>
        </select>
      </div>
   
    </div>
  
    <div class="col-md-6 ">
      <div class="row d-flex justify-content-between">
  
      
        <div class="col-md-6 ">
        <h6 for="fromDate" class="ml-2" style="color: grey;">
          {{curentLang === 'en' ? 'From Date' : (curentLang === 'ar' ? 'من تاريخ' : '  מתאריך')}}
        </h6>
        <div  >
          <p-calendar [(ngModel)]="fromDate" style="display: block; width: 100%;"
           showIcon="true" (ngModelChange)="onFromDateChange($event)"
              formControlName="startDate" 
              dateFormat="dd/mm/yy"
               placeholder="DD/MM/YYYY"
                >
              </p-calendar>
  
        </div>
      </div>
  
        <div class="col-md-6">
          <h6 for="toDate"  style="color: grey;">
           {{curentLang === 'en' ? 'To Date' : (curentLang === 'ar' ? 'الي تاريخ' : ' עד תאריך  ')}}
         </h6>
         <div class="">
         <p-calendar 
         style="display: block; width: 100%;"
           [(ngModel)]="toDate" 
           showIcon="true" 
           (ngModelChange)="onToDateChange($event)" 
           formControlName="endDate" 
           dateFormat="dd/mm/yy" 
           placeholder="DD/MM/YYYY">
         </p-calendar>
        </div>
     </div>
  
      </div>
    </div>
    
   
    
     
    
    <div class="col-md-3 mt-4 " style="padding-top: 6px;">
      <div class="form-group search  ">
        <button class="btn btn-sm mx-1  mt-2 " style="background-color: #141414; color: #fff;" 
        [disabled]="!fromDate || !toDate"
        (click)="getInvoicesFilters(fromDate, toDate)">
          <i class="fa fa-refresh mx-1"></i>
          {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
        </button>
      </div>
    </div>
  </div>
     
     
    
      </form>
    
      </div>
        </div>

      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive mt-2">
            <table class="table table-light text-center table-hover d-none d-lg-table " >
              <thead>
                <tr>
                  <th scope="col"> {{ curentLang === 'en' ? 'Confirmation Number' : (curentLang === 'ar' ? '  رقم التأكيد  ' : '  מס׳ אישור ')}} </th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Document Number' : (curentLang === 'ar' ? '   رقم المستند ' : ' מס׳    ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Invoice Reference Number' : (curentLang === 'ar' ? '    رقم مرجع الفاتورة  ' : 'מס מסמך')}}</th>
                  <!-- <th scope="col"> {{ curentLang === 'en' ? 'Vat Number' : (curentLang === 'ar' ? '      الرقم الضريبي  ' : 'מספר מס ')}}</th> -->
                  <th scope="col"> {{ curentLang === 'en' ? 'Invoice Date' : (curentLang === 'ar' ? '  تاريخ الفاتورة ' : '    תאריך  ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Company Name  ' : (curentLang === 'ar' ? ' أسم الشركة ' : 'שם החברה  ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Customer Name  ' : (curentLang === 'ar' ? '  أسم العميل   ' : ' שם   ')}}</th>
                  <!-- <th scope="col"> {{ curentLang === 'en' ? 'Customer Vat Number  ' : (curentLang === 'ar' ? '  الرقم الضريبي العميل   ' : '    מספר מס לקוח')}}</th> -->
                  <th scope="col"> {{ curentLang === 'en' ? 'Amount Before Discount  ' : (curentLang === 'ar' ? ' المبلغ قبل الخصم   ' : 'מחיר לפני הנחה   ')}}</th>
                  <!-- <th scope="col"> {{ curentLang === 'en' ? ' Discount  ' : (curentLang === 'ar' ? 'الخصم  ' : ' הנחה   ')}}</th> -->
                  <th scope="col"> {{ curentLang === 'en' ? ' Payment Amount  ' : (curentLang === 'ar' ? 'المبلغ المدفوع  ' : ' סה"כ ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? ' Vat Amount ' : (curentLang === 'ar' ? ' قيمة الضريبة  ' : '   מע"מ   ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? ' Invoice Type' : (curentLang === 'ar' ? 'نوع الفاتورة' : '    סוג מסמך     ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? ' Document Status' : (curentLang === 'ar' ? 'حالة المستند  ' : 'מצב מסמך')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '   تأكيد' : '    אישור   ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  עריכה ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Procedures  ' : (curentLang === 'ar' ? '   الاجراءات  ' : '  נהלים ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Procedure Status  ' : (curentLang === 'ar' ? '   حالة الاجراء  ' : '  מצב ההליך ')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'View  ' : (curentLang === 'ar' ? ' معاينة المستند  ' : 'הצגה ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of invoicesDataFilters | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total} | filter: term; let i = index"
                [ngClass]="{'table-danger': selectedInvoiceUuid === item.invoice_uuid }"
                 >
                  <td>{{item.confirmation_number}}</td>
                  <td>{{item.document_number}}</td>
                  <td>{{item.invoice_reference_number}}</td>
                  <td>{{ item.invoice_date | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ curentLang === 'en' ? item.company?.company_name_en.substring(0, 10) : (curentLang === 'ar' ? item.company?.company_name_ar.substring(0, 10) : item.company?.company_name_he.substring(0, 10)) }}</td>
                  <td>{{ curentLang === 'en' ? item?.customer_name.substring(0, 10) : (curentLang === 'ar' ? item?.customer_name.substring(0, 10) : item?.customer_name.substring(0, 10)) }}</td>
                  <td>{{ item.amount_before_discount }}</td>
                  <td>{{ item.payment_amount_including_vat }}</td>
                  <td>{{ item.all_vat_amount }}</td>
                  <td *ngIf=" item?.invoice_type == 305 "><span>{{curentLang === 'en' ? 'Tax Invoice' : (curentLang === 'ar' ? 'فاتورة ضريبية' : ' חשבונית מס') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 320 "><span>{{curentLang === 'en' ? 'Invoice With Receipt' : (curentLang === 'ar' ? 'فاتورة مع ايصال' : ' חשבונית עם חשבונית') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 332 "><span>{{curentLang === 'en' ? ' Financial Request' : (curentLang === 'ar' ? 'مطالبة مالية' : ' בקשה פינאלית') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 400 "><span>{{curentLang === 'en' ? 'Receipt' : (curentLang === 'ar' ? ' ايصال' : '  חשבונית') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 330 "><span>{{curentLang === 'en' ? 'Reverce Invoice' : (curentLang === 'ar' ? ' فاتورة عكسية' : '  חשבונית עם חשבונית') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 100 "><span>{{curentLang === 'en' ? 'order ' : (curentLang === 'ar' ? ' طلبية  ' : '     לְהַזמִין   ') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 200 "><span>{{curentLang === 'en' ? 'mission ' : (curentLang === 'ar' ? ' ارسالية  ' : 'תעודת משלוח') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 000 "><span>{{curentLang === 'en' ? 'Offer Price ' : (curentLang === 'ar' ? ' عرض سعر  ' : ' מחיר הצעה') }}</span></td>
                  
                  <td *ngIf=" item?.is_deleted == 1 "><span style="color: red;">{{curentLang === 'en' ? 'Cancelled    ' : (curentLang === 'ar' ? '  ملغي' : ' מסמך בוטל  ') }}</span></td>
                  <td *ngIf=" item?.is_deleted == 0 "><span >{{curentLang === 'en' ? 'Not cancelled' : (curentLang === 'ar' ? '  مؤكد ' : 'סופי') }}</span></td>
                  <td *ngIf="item.confirm == 0">
                    <button [disabled]="user_type == 200" style="background-color: #213FA6; color: white;" (click)="getId(item?.invoice_id, item?.company_id); openVerticallyCenteredConfirmed(dataConfirm)" class="btn btn-xs">
                      <i class="fa fa-check-circle-o text-white mx-1"></i> {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
                    </button>
                  </td>

                  <td *ngIf="item.confirm == 1">
                    <span class="text-success" style="font-weight: 700;" ><i class="fa fa-check-circle" ></i></span>
                  </td>

                

                  <td *ngIf="item.confirm == 0">
                    <i [hidden]="user_type == 200" class="fa fa-edit" style="cursor: pointer; color: green;" routerLink="/dashboard/update-invoice/{{item.invoice_uuid}}"></i>
                    <i [hidden]="user_type == 100" class="fa fa-close" style="color: grey;"></i>
                  </td>

             
                  <td *ngIf="item.confirm == 1">
                    <i class="fa fa-close" style="color: grey;"></i>
                  </td>

                  <td  *ngIf="item.is_in_procedure_cycle == true" >
                    <i class="fa fa-gears" style="color: rgb(225, 74, 19); cursor: pointer;" (click)="getId(item?.invoice_id, item?.company_id); openProceduresModal(dataProcedures)" >

                    </i>
                  </td>

                  <td *ngIf="item.is_in_procedure_cycle == false">
                    <i class="fa fa-close" style="color: grey;"></i>
                  </td>
                  <td *ngIf=" item?.procedure == null "><span>{{curentLang === 'en' ? 'x' : (curentLang === 'ar' ? '   x    ' : '  x ') }}</span></td>

                  <td *ngIf=" item?.procedure == 1 "><span>{{curentLang === 'en' ? 'Issuing an Invoice Without an Allocation Number  ' : (curentLang === 'ar' ? '   اصدار فاتورة بدون رقم تخصيص' : '  הפקה ללא מספר הקצאה  ') }}</span></td>
                  <td *ngIf=" item?.procedure == 3 "><span>{{curentLang === 'en' ? 'Canceling Invoice ' : (curentLang === 'ar' ? '   إلغاء الفاتورة     ' : '  ביטול חשבונית         ') }}</span></td>
                  <td *ngIf=" item?.procedure == 2 "><span>{{curentLang === 'en' ? 'Request a Hearing Session ' : (curentLang === 'ar' ? '     طلب جلسة استماع     ' : '  בקשת שימוע           ') }}</span></td>
                  <td *ngIf=" item?.procedure == 4 "><span>{{curentLang === 'en' ? 'Reversing the Invoice to the Customer (Invoice Without Transaction) ' : (curentLang === 'ar' ? '     عكس الفاتورة على الزبون (فاتورة بدون ماعام)    ' : 'היפוך חיוב (חשבונית ללא מע״מ )') }}</span></td>

                 
              <!--
                <td>
                    <i class="fa fa-file-pdf-o"
                     [ngStyle]="{'pointer-events': item.confirm == 0 ? 'none' : 'auto', 'opacity': item.confirm == 0 ? 0.6 : 1}" style="color: grey; font-size: 15px; cursor: pointer; color: #213FA6;"
                     (click)="executeFunctions(item.invoice_uuid );openPdfModal(item.pdf_link , content4)" ></i>
                  </td>   
              -->
                 

              <td>
                <i class="fa fa-file-pdf-o"  
                *ngIf="item.document_source == 19   "
                   [ngStyle]="{
                       'pointer-events': item.confirm == 0 ? 'none' : 'auto',
                       'opacity': item.confirm == 0 ? 0.6 : 1,
                       'color': '#213FA6',
                       'font-size': '15px',
                       'cursor': 'pointer'
                   }"
                   (click)="executeFunctions(item.invoice_uuid); openPdfModal(item, content4)">
                </i>
            
                <i *ngIf=" item.document_source == 20" 
                class="fa fa-file-pdf-o"
                style="color: #213FA6; font-size: 15px; cursor: pointer;"
                (click)="executeFunctions(item.invoice_uuid); openInvoiceXl2(content5 )">
             </i>
            </td>
            

              

            <td *ngIf="item.confirm == 0">
              <button [disabled]="user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted'" 
                      [ngStyle]="{'pointer-events': item.customer_status == 1 ? 'none' : 'auto', 'color': item.customer_status == 1 ? 'gray' : 'red'}" 
                      class="btn" style="border: none;" 
                      (click)="getId(item?.invoice_id, item?.company_id); openVerticallyCentered(dataDeleted)">
                <i class="fa fa-trash-o" [ngStyle]="{'font-size': '15px', 'cursor': 'pointer', 'color': (user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted') ? 'gray' : 'red'}"></i>
              </button>
            </td>
            
            <td *ngIf="item.confirm != 0">
              <button *ngIf="item.confirm == 1 && !(item.is_deleted == 1)" 
                      [disabled]="user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted'" 
                      [ngStyle]="{'pointer-events': item.customer_status == 1 ? 'none' : 'auto', 'color': item.customer_status == 1 ? 'gray' : 'red'}" 
                      class="btn" style="border: none;" 
                      (click)="getId(item?.invoice_id, item?.company_id); openVerticallyCenteredReverse(reverseInvoice)">
                <i class="fa fa-trash-o" [ngStyle]="{'font-size': '15px', 'cursor': 'pointer', 'color': (user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted') ? 'gray' : 'red'}"></i>
              </button>
              
              <button *ngIf="item.is_deleted == 1 || !item.confirm == 1" 
                      [disabled]="true"  
                      class="btn" style="border: none;">
                <i class="fa fa-close" style="color: gray;"></i>
              </button>
            </td>

                </tr>
              </tbody>
              
            </table>



<!-- invoices Info on phone -->
 

  <div class="container-fluid basic_table d-lg-none my-2" >

    <div class="row">
<div class="col-sm-12">
   
    <div class="card"  id="headingTwo"
     *ngFor="let item of invoicesDataFilters  | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total} |filter:term ; let i = index ">
    
            <div  
            
            (click)="toggleCollapse(i)" 
            [attr.aria-expanded]="openedIndex === i" 
            aria-controls="collapse"
            style="cursor: pointer; background-color: #F6F6F9;" 
            class="py-1">
            <h6>
              <i class="fa fa-file mx-1"  style="color: #000; font-size: 15px;" > </i>
              <span style="color: #000; font-size: 15px;" class="mx-2">
                {{ curentLang === 'en' ? 'Invoice Number' : (curentLang === 'ar' ? '   رقم الفاتورة ' : '  מס׳ ')}} :
              </span>
              <span style="color: #000; font-size: 15px;" class="mx-2">{{item.document_number}}</span>
            </h6>
              <h6>
                <i class="icofont icofont-businessman mx-1"  style="color: #000; font-size: 15px;" > </i>
                <span style="color: #000; font-size: 15px;" class="mx-2">
                  {{ curentLang === 'en' ? 'Customer Name ' : (curentLang === 'ar' ? 'أسم الزبون ' : 'שם הלקוח')}} :
                </span>
                <span style="color: #000; font-size: 15px;" class="mx-2">{{item.customer_name}}</span>
              </h6>

            </div>
      
             
            <div class="row d-flex justify-content-between">

              <div class="col-md-12  col-sm-12 ">
                <div class="row collapse   show" id="collapseTwo" [ngbCollapse]="openedIndex !== i">

               
                <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                    {{ curentLang === 'en' ? 'Confirmation Number' : (curentLang === 'ar' ? '  رقم التأكيد  ' : ' מס׳ אישור  ')}} 
                  </span>
                    <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" ></span>
                    <span class="col-sm-4   text-end ">
                      <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item.confirmation_number}}</span>
                    </span>
                 </div>
                 <hr style="border: solid 1px #141414;" >
                 <!---->
      
                 <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                    {{ curentLang === 'en' ? 'Document Number' : (curentLang === 'ar' ? '   رقم المستند ' : '  מס׳ ')}}
                  </span>

                  <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" ></span>

                  <span class="col-sm-4   text-end ">
                    <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item.document_number}}</span>
                  </span>
                  
                     
     
                 </div>
                 <hr style="border: solid 1px #141414;" >
                <!---->
                <div  
            
                (click)="toggleCollapse2(i)" 
                [attr.aria-expanded]="openedIndex2 === i" 
                aria-controls="collapse"
                style="cursor: pointer; background-color: #F6F6F9;" 
                class="py-1">
            
                  <h6>
                    <i class="fa fa-plus mx-1"  style="color: #000; font-size: 15px;" > </i>
                    <span style="color: #000; font-size: 15px;" class="mx-2">
                      {{ curentLang === 'en' ? 'Invoice contents' : (curentLang === 'ar' ? 'محتويات الفاتورة   ' :  'תוכן חשבונית')}} :
                    </span>
                     
                  </h6>


                  <div class="row collapse   show" id="collapseTwo" [ngbCollapse]="openedIndex2 !== i">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class=" table-responsive">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th style="color: #141414; font-size: 14px;">
                                {{ curentLang === 'en' ? 'Product Name' : (curentLang === 'ar' ? 'اسم المنتج' : 'שם מוצר') }}
                              </th>
                              <th style="color: #141414; font-size: 14px;">
                                {{ curentLang === 'en' ? 'Category' : (curentLang === 'ar' ? 'قسم المنتج' : 'מדור מוצר') }}
                              </th>
                              <th style="color: #141414; font-size: 14px;">
                                {{ curentLang === 'en' ? 'Description' : (curentLang === 'ar' ? 'الوصف' : 'תיאור') }}
                              </th>
                              <th style="color: #141414; font-size: 14px;">
                                {{ curentLang === 'en' ? 'Quantity' : (curentLang === 'ar' ? 'الكمية' : 'כמות') }}
                              </th>
                              <th style="color: #141414; font-size: 14px;">
                                {{ curentLang === 'en' ? 'Price per Unit' : (curentLang === 'ar' ? 'سعر الوحدة' : 'מחיר ליחידה') }}
                              </th>
                              <th style="color: #141414; font-size: 14px;">
                                {{ curentLang === 'en' ? 'Total Amount' : (curentLang === 'ar' ? 'المبلغ الإجمالي' : 'סה"כ סכום') }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let items of item?.soldItems">
                              <td style="font-size: 13px; color: #141414;">{{ items.item_name }}</td>
                              <td style="font-size: 13px; color: #141414;">{{ items.category }}</td>
                              <td style="font-size: 13px; color: #141414;">{{ items.description }}</td>
                              <td style="font-size: 13px; color: #141414;">{{ items.quantity }}</td>
                              <td style="font-size: 13px; color: #141414;">{{ items.price_per_unit | number:'1.0-2' }}</td>
                              <td style="font-size: 13px; color: #141414;">{{ items.total_amount | number:'1.0-2' }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    
                    </div>
                    
                     <hr style="border: solid 1px #141414;" >
                  </div>
    
                </div>
                 <!---->
                 <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                    {{ curentLang === 'en' ? 'Invoice Reference Number' : (curentLang === 'ar' ? '    رقم مرجع الفاتورة  ' : 'מס מסמך')}}
                  </span>
                  
                  <div class="text-center col-sm-4">  </div>

                    <span style="color: #141414;   " class="col-sm-4   text-end "  >
                      <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item.invoice_reference_number}}</span>
                  </span>

                
                 </div>
                 <hr style="border: solid 1px #141414;" >

                   <!---->

                   <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                    <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                      {{ curentLang === 'en' ? 'Invoice Date' : (curentLang === 'ar' ? '  تاريخ الفاتورة ' : '    תאריך  ')}}

                    </span>
                    
                    <div class="text-center col-sm-4">  </div>
  
                      <span style="color: #141414;   " class="col-sm-4   text-end "  >
                        <span class="  px-5 "  style="color: #141414; font-size: 16px ;" > {{ item.invoice_date | date: 'dd/MM/yyyy' }}</span>
                    </span>
  
                  
                   </div>
                   <hr style="border: solid 1px #141414;" >
                    <!---->

                    <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                        {{ curentLang === 'en' ? 'Company Name  ' : (curentLang === 'ar' ? ' أسم الشركة ' : 'שם החברה  ')}}  
                      </span>
                      
                      <div class="text-center col-sm-4">  </div>
    
                        <span style="color: #141414;   " class="col-sm-4   text-end "  >
                          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" > 
                            {{ curentLang === 'en' ? item.company?.company_name_en : (curentLang === 'ar' ? item.company?.company_name_ar : item.company?.company_name_he) }}
                          </span>
                      </span>
    
                    
                     </div>
         
                   <hr style="border: solid 1px #141414;" >
                    <!---->

                    <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                        {{ curentLang === 'en' ? 'Customer Name  ' : (curentLang === 'ar' ? '  أسم العميل   ' : ' שם   ')}}                
                      </span>
                      
                      <div class="text-center col-sm-4">  </div>
                
                        <span style="color: #141414;   " class="col-sm-4   text-end "  >
                          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{ curentLang === 'en' ? item?.customer_name : (curentLang === 'ar' ? item?.customer_name : item?.customer_name) }}</span>
                      </span>
                
                    
                     </div>
                 <hr style="border: solid 1px #141414;" >
                    <!---->

    <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
        {{ curentLang === 'en' ? 'Amount Before Discount  ' : (curentLang === 'ar' ? ' المبلغ قبل الخصم   ' : 'מחיר לפני הנחה  ')}}
      </span>
      
      <div class="text-center col-sm-4">  </div>

        <span style="color: #141414;   " class="col-sm-4   text-end "  >
          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" > {{ item.amount_before_discount }}</span>
      </span>

    
     </div>
    
   <hr style="border: solid 1px #141414;" >

       <!---->

       <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

        <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
          {{ curentLang === 'en' ? ' Payment Amount  ' : (curentLang === 'ar' ? 'المبلغ المدفوع  ' : '  סה"כ  ')}}  
        </span>
        
        <div class="text-center col-sm-4">  </div>
        <span style="color: #141414;   " class="col-sm-4   text-end "  >
          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{ item.payment_amount_including_vat }}</span>
      </span>
  
      
       </div>
     <hr style="border: solid 1px #141414;" >
      <!---->

        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? ' Vat Amount ' : (curentLang === 'ar' ? ' قيمة الضريبة  ' : '   מע"מ   ')}}
          </span>
          
          <div class="text-center col-sm-4">  </div>
          <span style="color: #141414;   " class="col-sm-4   text-end "  >
            <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{ item.all_vat_amount }}</span>
        </span>
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->


        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? ' Invoice Type' : (curentLang === 'ar' ? 'نوع الفاتورة' : '   סוג מסמך    ')}}
          </span>
          
          <div class="text-center col-sm-4">  </div>
          <span style="color: #141414;   " class="col-sm-4   text-end "  >
            <span class="  px-5 "  *ngIf=" item?.invoice_type == 305 "><span>{{curentLang === 'en' ? 'Tax Invoice' : (curentLang === 'ar' ? 'فاتورة ضريبية' : ' חשבונית מס') }}</span></span>
            <span class="  px-5 "  *ngIf=" item?.invoice_type == 320 "><span>{{curentLang === 'en' ? 'Invoice With Receipt' : (curentLang === 'ar' ? 'فاتورة مع ايصال' : ' חשבונית עם חשבונית') }}</span></span>
            <span class="  px-5 "  *ngIf=" item?.invoice_type == 332 "><span>{{curentLang === 'en' ? ' Financial Request' : (curentLang === 'ar' ? 'مطالبة مالية' : ' בקשה פינאלית') }}</span></span>
            <span class="  px-5 "  *ngIf=" item?.invoice_type == 400 "><span>{{curentLang === 'en' ? 'Receipt' : (curentLang === 'ar' ? ' ايصال' : '  חשבונית') }}</span></span>
            <span class="  px-5 "  *ngIf=" item?.invoice_type == 330 "><span>{{curentLang === 'en' ? 'Reverce Invoice' : (curentLang === 'ar' ? ' فاتورة عكسية' : '  חשבונית עם חשבונית') }}</span></span>
 
            <span class="  px-5 " *ngIf=" item?.invoice_type == 100 "><span>{{curentLang === 'en' ? 'order ' : (curentLang === 'ar' ? ' طلبية  ' : '     לְהַזמִין   ') }}</span></span>
            <span class="  px-5 " *ngIf=" item?.invoice_type == 200 "><span>{{curentLang === 'en' ? 'mission ' : (curentLang === 'ar' ? ' ارسالية  ' : 'תעודת משלוח') }}</span></span>
            <span class="  px-5 " *ngIf=" item?.invoice_type == 000 "><span>{{curentLang === 'en' ? 'Offer Price ' : (curentLang === 'ar' ? ' عرض سعر  ' : ' מחיר הצעה') }}</span></span>
        </span>
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->
        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? ' Document Status' : (curentLang === 'ar' ? 'حالة المستند  ' : 'מצב מסמך')}}
          </span>
          
          <div class="text-center col-sm-4">  </div>
          <span style="color: #141414;   " class="col-sm-4   text-end "  >
            <span class="  px-5 "  *ngIf=" item?.is_deleted == 1 "><span style="color: red;">{{curentLang === 'en' ? 'Cancelled    ' : (curentLang === 'ar' ? '  ملغي' : ' מסמך בוטל  ') }}</span></span>
            <span class="  px-5 "  *ngIf=" item?.is_deleted == 0 "><span >{{curentLang === 'en' ? 'Not cancelled' : (curentLang === 'ar' ? '  مؤكد ' : 'סופי') }}</span></span>
   
        </span>
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->

        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '   تأكيد' : '    אישור   ')}}
          </span>
          
          <div class="text-center col-sm-4">  </div>
          <span style="color: #141414;   " class="col-sm-4    text-end "  >
             <span *ngIf="item.confirm == 0" class="px-5">
                    <button [disabled]="user_type == 200" style="background-color: #213FA6; color: white;" (click)="getId(item?.invoice_id, item?.company_id); openVerticallyCenteredConfirmed(dataConfirm)" class="btn btn-xs mb-3">
                      <i class="fa fa-check-circle-o text-white mx-1"></i> {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
                    </button>
                  </span>
                  
                  <span *ngIf="item.confirm == 1" class="px-5">
                    <span class="text-success" style="font-weight: 700;"><i class="fa fa-check-circle"></i></span>
                  </span>
        </span>
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->


        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  עריכה ')}}
          </span>
          
          <div class="text-center col-sm-4">  </div>
          
          <span style="color: #141414;   " class="col-sm-4    text-end "  >
            <span *ngIf="item.confirm == 0" class="px-5">
              <i [hidden]="user_type == 200" class="fa fa-edit" style="cursor: pointer; color: green;" routerLink="/dashboard/update-invoice/{{item.invoice_uuid}}"></i>
              <i [hidden]="user_type == 100" class="fa fa-close" style="color: grey;"></i>
            </span>

            <span *ngIf="item.confirm == 1"class="px-5">
              <i class="fa fa-close" style="color: grey;"></i>
            </span>
        </span>
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->

  
        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? 'Procedures  ' : (curentLang === 'ar' ? '   الاجراءات  ' : '  נהלים ')}}
          </span>
          
          <div class="text-center col-sm-4">  </div>
          
          <span style="color: #141414;   " class="col-sm-4    text-end "  >
            <span *ngIf="item.is_in_procedure_cycle == true" class="px-5">
              <i class="fa fa-gears" style="color: rgb(225, 74, 19); cursor: pointer;" (click)="getId(item?.invoice_id, item?.company_id); openProceduresModal(dataProcedures)" >

              </i>
            </span>

            <span *ngIf="item.is_in_procedure_cycle == false" class="px-5">
              <i class="fa fa-close" style="color: grey;"></i>
            </span>
        </span>
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->

                


        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? 'Procedure Status  ' : (curentLang === 'ar' ? '   حالة الاجراء  ' : '  מצב ההליך ')}}
          </span>
          
          <div class="text-center col-sm-4">  </div>
          <span style="color: #141414;   " class="col-sm-4   text-center "  >
            <span class="  px-5 "  *ngIf=" item?.procedure == null "><span>{{curentLang === 'en' ? 'x ' : (curentLang === 'ar' ? 'x' : 'x') }}</span></span>

            <span class="  px-5 "  *ngIf=" item?.procedure == 1 "><span>{{curentLang === 'en' ? 'Issuing an Invoice Without an Allocation Number  ' : (curentLang === 'ar' ? '   اصدار فاتورة بدون رقم تخصيص' : '  הפקה ללא מספר הקצאה  ') }}</span></span>
            <span class="  px-5 "  *ngIf=" item?.procedure == 3 "><span>{{curentLang === 'en' ? 'Canceling Invoice ' : (curentLang === 'ar' ? '   إلغاء الفاتورة     ' : '  ביטול חשבונית         ') }}</span></span>
            <span class="  px-5 "  *ngIf=" item?.procedure == 2 "><span>{{curentLang === 'en' ? 'Request a Hearing Session ' : (curentLang === 'ar' ? '     طلب جلسة استماع     ' : '  בקשת שימוע           ') }}</span></span>
            <span class="  px-5 "  *ngIf=" item?.procedure == 4 "><span>{{curentLang === 'en' ? 'Reversing the Invoice to the Customer (Invoice Without Transaction) ' : (curentLang === 'ar' ? '     عكس الفاتورة على الزبون (فاتورة بدون ماعام)    ' : 'היפוך חיוב (חשבונית ללא מע״מ )') }}</span></span>
  
          </span>
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
         <!---->

        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? 'View  ' : (curentLang === 'ar' ? ' معاينة المستند  ' : 'הצגה ')}}
          </span>
          
          <div class="text-center col-sm-4">  </div>
          
          <span style="color: #141414;   " class="col-sm-4    text-end "  >
            <span class="px-5" >
              <i class="fa fa-file-pdf-o"    *ngIf="item.document_source == 19   "
              (click)="executeFunctions(item.invoice_uuid );openPdfModal(item , content4)" style="cursor: pointer;"
              [ngStyle]="{ 'pointer-events': item.confirm == 0 ? 'none' : 'auto', 'opacity': item.confirm == 0 ? 0.6 : 1 }" 
               ></i>

               <i   *ngIf="item.document_source == 20   "
               class="fa fa-file-pdf-o"
               style="color: #213FA6; font-size: 15px; cursor: pointer;"
               (click)="executeFunctions(item.invoice_uuid); openInvoiceXl2(content5 )">
            </i>
            </span >
        </span>
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->

        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}

          </span>
          
          <div class="text-center col-sm-4">  </div>
          
          <span style="color: #141414;" class="col-sm-4 text-end">
            <!-- زر الحذف عند item.confirm == 0 -->
            <span *ngIf="item.confirm == 0">
              <button
                [disabled]="user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted'"
                [ngStyle]="{
                  'pointer-events': item.customer_status == 1 ? 'none' : 'auto',
                  color: item.customer_status == 1 ? 'gray' : 'red'
                }"
                class="btn px-5"
                style="border: none;"
                (click)="getId(item?.invoice_id, item?.company_id); openVerticallyCentered(dataDeleted)"
              >
                <i
                  *ngIf="!(user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted')"
                  class="fa fa-trash-o"
                  [ngStyle]="{
                    'font-size': '15px',
                    cursor: 'pointer',
                    color: 'red'
                  }"
                ></i>
                <i
                  *ngIf="user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted'"
                  class="fa fa-close"
                  [ngStyle]="{
                    'font-size': '15px',
                    color: 'gray'
                  }"
                ></i>
              </button>
            </span>
          
            <!-- زر الحذف عند item.confirm == 1 -->
            <span *ngIf="item.confirm == 1 && item.is_deleted != 1">
              <button
                [disabled]="user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted'"
                [ngStyle]="{
                  'pointer-events': item.customer_status == 1 ? 'none' : 'auto',
                  color: item.customer_status == 1 ? 'gray' : 'red'
                }"
                class="btn px-5"
                style="border: none;"
                (click)="getId(item?.invoice_id, item?.company_id); openVerticallyCenteredReverse(reverseInvoice)"
              >
                <i
                  *ngIf="!(user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted')"
                  class="fa fa-trash-o"
                  [ngStyle]="{
                    'font-size': '15px',
                    cursor: 'pointer',
                    color: 'red'
                  }"
                ></i>
                <i
                  *ngIf="user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted'"
                  class="fa fa-close"
                  [ngStyle]="{
                    'font-size': '15px',
                    color: 'gray'
                  }"
                ></i>
              </button>
            </span>
          </span>
          
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->


                </div>
              </div>

            </div>
            <hr style="color: #141414; border: solid 1px;">
    </div>

 


  </div>

    </div>
</div>










            <div *ngIf="invoicesDataFilters.length == 0" class="alert text-danger mt-3">
              <span> {{ curentLang === 'en' ? 'There are no invoices for this company yet... ' : (curentLang === 'ar' ? ' لا توجد فواتير لهذة الشركة حتي الأن ... ' : ' עדין לא הופקו חשובניות מס ')}}</span>
            </div>
            
            <div class="pagination-container">
              <pagination-controls 
                (pageChange)="onPageChange($event)" 
                (totalItems)="total" 
                (currentPage)="currentPage">
              </pagination-controls>

              <input type="number" class="mt-1" [(ngModel)]="customLimit" (change)="onLimitChange()">

            </div>
          
         
          </div>

          <div class=" d-flex justify-content-between"> 
            <div></div>

           <div *ngIf="user_type == 100 || user_type == 101">
            <a routerLink="/dashboard/merchant-info">
                <button class="btn btn-danger m-2 " *ngIf="!isInsideModal">
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
           </div>

           <div *ngIf="user_type == 200 || user_type == 201">
            <a routerLink="/dashboard/users">
                <button class="btn btn-danger m-2 " >
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
           </div>

          </div>

        </div>
      </div>
    </div>
  </div>

    </div>
</div>





     <!--Delete invoice-->

     <ng-template #dataDeleted let-modal>
      <div class="modal-header">
          <h4 class="modal-title"></h4>
          <button type="button" class="close btn-close"  style="color: grey;"  aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body">
          <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
            {{curentLang === 'en' ? 'Are you sure you want to delete this invoice?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذف هذة الفاتورة؟' : ' האם אתה בטוח שברצונך למחוק חשבונית זו? ') }}
          </p>
      </div>
      <div class="modal-footer">

        <button type="button" 
         class="btn btn-sm m-auto mt-2" 
        style="background-color: #141414; color: #fff;"
         (click)="deleteInvoiceById()"
         > 
          {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
          <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
      </button>
  
          <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
              <i class="fa fa-rotate-left mx-1"></i>
          </button>
  
        
  
      </div>
  </ng-template>


   <!--Reverse Invoice-->

<ng-template #reverseInvoice let-modal>
  <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close btn-close"  style="color: grey;"  aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-center text-danger">
      <i class="fa fa-trash text-danger"></i>
      {{curentLang === 'en' ? 'Sorry: You cannot delete a confirmed invoice. Do you want to convert this invoice to a reverse invoice?' : (curentLang === 'ar' ? 'عذرا: لا يمكنك حذف فاتورة مؤكدة, هل تريد تحويل هذه الفاتورة إلى فاتورة عكسية؟' : 'סליחה: אינך יכול למחוק חשבונית מאושרת האם אתה רוצה להמיר חשבונית זו לחשבונית הפוכה?')}}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm m-auto mt-2" style="background-color: #141414; color: #fff;" (click)="checkAndConvertToReverse(invoiceId, companyId)">
      {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
      <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>
    <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
      {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
      <i class="fa fa-rotate-left mx-1"></i>
    </button>
  </div>
</ng-template>


  



              <!--confirm-->

              <ng-template #dataConfirm let-modal>
                <div class="modal-header">
                <h4 class="modal-title"></h4>
                <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body text-center">
                  <p class="text-center text-danger"> <i class="fa fa-check-circle  text-danger"></i>
                    {{curentLang === 'en' ? 'Are you sure you want to confirm this invoice?' : (curentLang === 'ar' ? '  هل تريد تأكيد هذةالفاتورة؟' : '   האם אתה בטוח שברצונך לאשר חשבונית זו? ') }}
                  </p>
                </div>
              
                <div class="modal-footer">

                  <button type="button" 
                   class="btn btn-sm m-auto mt-2" 
                  style="background-color: #141414; color: #fff;"
                   (click)="confirmInvoice()"
                   > 
                    {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                    <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                </button>
            
                    <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                        {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                        <i class="fa fa-rotate-left mx-1"></i>
                    </button>
            
                  
            
                </div>


              </ng-template>





              <ng-template #content4 let-modal    >
                <div class="modal-header d-flex justify-content-between">
                  <h6 class="modal-title" style="color: grey;">
                    {{curentLang === 'en' ? 'Invoice Details' : (curentLang === 'ar' ? 'تفاصيل الفاتورة ' : 'פרטי חשבונית')}}
                  </h6>
                  <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              
                <div class="modal-body">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-md-4 mt-4">
                        <button type="button" (click)="downloadDocumentPdf(); modal.close('Close click')" class="btn btn-sm mx-4 mt-4"
                          style="background-color: #141414; color: #fff;">
                          <i class="fa fa-file-pdf-o mx-1"></i>
                          {{ curentLang === 'en' ? 'Print' : (curentLang === 'ar' ? 'طباعة نسخة' : 'הפקת העתק') }}
                        </button>
                      </div>
              
                      <div class="col-md-4 mt-4">
                        <div class="dropdown mx-4 mt-4">
                          <button class="btn dropdown-toggle w-100" type="button" id="shareInvoiceDropdown" data-bs-toggle="dropdown"
                            aria-expanded="false" style="background-color: #141414; color: #fff;">
                            {{ curentLang === 'en' ? 'Share Original Version' : (curentLang === 'ar' ? 'مشاركة النسخة الأصلية' : 'שתף את המקור') }}
                            <i class="fa fa-share-alt mx-1 text-white" style="font-size: 15px;"></i>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="shareInvoiceDropdown">

                            <li style="border-bottom: 1px solid #000;">
                              <a class="dropdown-item" style="cursor: pointer; text-align: start; color: #000;" (click)="downloadOriginalDocPdfFromLink()">
                                <i class="fa fa-download mx-1" style="font-size: 15px; color: rgb(37, 16, 160);"></i>
                                {{ curentLang === 'en' ? 'Download original version': (curentLang === 'ar' ? 'تحميل النسخة الأصلية  ' : 'הורד את הגרסה המקורית') }}
                              </a>
                            </li>

                            <li style="border-bottom: 1px solid #000;">
                              <a class="dropdown-item" style="cursor: pointer; text-align: start; color: #000;" (click)="copyLinkToClipboard(rawPdfUrl)">
                                <i class="fa fa-copy mx-1" style="font-size: 15px; color: rgb(158, 40, 40);"></i>
                                {{ curentLang === 'en' ? 'Copy Link' : (curentLang === 'ar' ? 'نسخ الرابط' : 'העתק קישור') }}
                              </a>
                            </li>
                            <li style="border-bottom: 1px solid #000;">
                              <a class="dropdown-item" style="cursor: pointer; text-align: start; color: #000;" href="https://wa.me/?text={{ rawPdfUrl }}" target="_blank">
                                  <i class="fa fa-whatsapp mx-1" style="font-size: 15px; color: rgb(32, 92, 32);"></i>
                                  {{ curentLang === 'en' ? 'Share via WhatsApp' : (curentLang === 'ar' ? 'مشاركة عبر واتساب' : 'שתף בוואטסאפ') }}
                              </a>
                          </li>
                            <li>
                              <a class="dropdown-item" style="cursor: pointer; text-align: start; color: #000;" href="mailto:?subject=Invoice&body={{ rawPdfUrl }}">
                                <i class="fa fa-envelope mx-1" style="font-size: 15px; color: rgb(137, 137, 16);"></i>
                                {{ curentLang === 'en' ? 'Share via Email' : (curentLang === 'ar' ? 'مشاركة عبر البريد الإلكتروني' : 'שתף באימייל') }}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
              
                      <div class="col-md-4 my-2">
                        <div class="form-group mx-4">
                          <label class="mt-2" style="color: #141414;">
                            {{ curentLang === 'en' ? 'Change Document Language' : (curentLang === 'ar' ? 'تغيير لغة المستند' : 'שינוי שפת המסמך') }}
                          </label>
                          <select class="form-control" (change)="filterDocumentLanguage($event.target.value)">
                            <option *ngFor="let lang of availableLanguages" [value]="lang.code">
                              {{ lang.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
              
                    <div class="col-md-12">
                      <!-- عرض رسالة جاري تجهيز الملف أثناء عرض الهيكل العظمي -->
                      <div *ngIf="isSkelton">
                        <p class="text-center" style="color: grey;">{{ curentLang === 'en' ? 'The file is being prepared...' : (curentLang === 'ar' ? 'جاري تجهيز الملف' : 'הקובץ בהכנה') }}</p>
                        <div class="skeleton-loader">
                          <div class="border-round border-1 surface-border p-4 surface-card">
                            <div class="flex mb-3">
                              <div>
                                <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
                                <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
                                <p-skeleton height=".5rem"></p-skeleton>
                              </div>
                            </div>
                            <p-skeleton width="100%" height="150px"></p-skeleton>
                            <div class="flex justify-content-between mt-3">
                              <p-skeleton width="4rem" height="2rem"></p-skeleton>
                            </div>
                          </div>
                        </div>
                      </div>
              
                      <!-- عرض الـ PDF عند انتهاء الهيكل العظمي -->
                      <iframe *ngIf="!isSkelton && pdfUrl" [src]="pdfUrl" width="100%" height="500px" frameborder="0"></iframe>
                    </div>
                  </div>
              
                  <div class="modal-footer my-2">
                    <button type="button" class="btn btn-sm mt-2 btn-danger" (click)="modal.close('Close click')">
                      {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
                      <i class="fa fa-rotate-left mx-1"></i>
                    </button>
                  </div>
                </div>
              </ng-template>



            <ng-template #content5 let-modal  >
              <div class="modal-header d-flex justify-content-between">
                <h6 class="modal-title"style="color: grey;">
                  {{curentLang === 'en' ? 'Invoice Details' : (curentLang === 'ar' ? 'تفاصيل الفاتورة ' : 'פרטי חשבונית')}}
                </h6>
                <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">
                  <button type="button" (click)="downloadDocumentPdf();modal.close('Close click')" class="btn btn-sm my-3"
                style=" background-color: #141414; color: #fff;">
                <i class="fa fa-file-pdf-o mx-1"></i>
                {{ curentLang === 'en' ? ' Print ' : (curentLang === 'ar' ? 'طباعة نسخة  ' : 'הפקת העתק') }}
              </button>
            <div class="container" [hidden]="invoiceInfoData?.confirm == 0" >
                <div class="row m-auto">
                    <div class="col-sm-12 ">
            
                        <div class="page m-auto  invoice-content"  id="printDiv">
            
                            <div class="card-2" style="background-color: #fff; box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);">
            
                                <div class="row m-2 ">
                                    <div class="col-lg-12">
                                        <div class="invoice-inner" id="invoice_wrapper">
                                            <div class="invoice-top">
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <div class="logo my-2"  *ngIf="invoiceInfoData?.logo" >
                                                            <img 
                                                                [src]="invoiceInfoData?.logo"
                                                                 title="company logo"
                                                                style="width: 100px;height: 100px; border-radius: 5%;">
                                                        </div>
                                                    </div>
            
                                                    <div [class.py-5]="invoiceInfoData?.logo" [class.py-2]="!invoiceInfoData?.logo" class="col-sm-4" *ngIf="user_type == 300 || user_type == 301">
                                                        <h2 class="text-center water-mark">
                                                            {{ curentLangDocument === 'en' ? ' Accounting Copy' : (curentLangDocument === 'ar' ? 'نسخة المحاسب' : ' עותק רואה חשבון')}}
                                                        </h2>
                                                    </div>
            
                                                    <div [class.py-5]="invoiceInfoData?.logo" [class.py-2]="!invoiceInfoData?.logo" class="col-sm-4" *ngIf="user_type == 200 || user_type == 201">
                                                        <h2 class="text-center water-mark">{{invoiceInfoData?.copy_version}}</h2>
                                                    </div>
                                                    
                                                    <div class="col-sm-4">
                                                 
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="invoice-info py-2">
                                                <div class="row">
                                                   
                                                    <div class="col-sm-6 mb-30">
                                                        <div class="invoice-number ">
                                                            <h4 class="inv-title-1" style="font-weight: bold; font-size: 14px;">{{ curentLangDocument === 'en' ? 'For ' : (curentLangDocument === 'ar' ? '   لحضرة' : 'לכבוד  ')}}</h4>
                                                            <div class="invo-addr-1">
                                                                <div style="font-weight: 400;">
                                                                    <!-- <strong> {{ curentLangDocument === 'en' ? 'Customer Name' : (curentLangDocument === 'ar' ? 'اسم العميل' : 'שם הלקוח')}} / </strong> -->
                                                                    <strong>{{ invoiceInfoData?.customer_name }}</strong>
                                                                </div>
                                                                
                                                                <div *ngIf="invoiceInfoData?.customer_address; else noAddress" style="font-weight: 300;" class="m-0">
                                                                    <strong>{{ invoiceInfoData?.customer_address }}</strong>
                                                                </div>
                                                                
                                                                <ng-template #noAddress>
                                                                    <div style="font-weight: 300;">
                                                                        <strong>{{ curentLangDocument === 'en' ? 'Customer Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : 'מס עוסק מורשה    ')}} </strong>
                                                                        <div style="font-weight: 300;">
                                                                            <strong>{{ invoiceInfoData?.customer_vat_number }}</strong>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>
                                                                
                                                                <div *ngIf="invoiceInfoData?.customer_address" style="font-weight: 300;">
                                                                    <strong>{{ curentLangDocument === 'en' ? 'Customer Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : '    מס עוסק מורשה ')}} </strong>
                                                                    <div style="font-weight: 300;">
                                                                        <strong>{{ invoiceInfoData?.customer_vat_number }}</strong>
                                                                    </div>
                                                                </div>
                                                                
            
                                                                
                                                                <div style="font-weight: 300;">
                                                                    <!-- <strong>{{ curentLangDocument === 'en' ? 'Customer Id Number' : (curentLangDocument === 'ar' ? '  رقم الهوية للعميل' : '  מספר זיהוי לקוח ')}} / </strong> -->
                                                                    <strong>{{ invoiceInfoData?.customer_id_number }}</strong>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
            
                                                    <div class="col-sm-6 mb-30">
                                                        <div class="invoice-number text-end">
                                                            <!-- <h4 class="inv-title-1"> {{ curentLangDocument === 'en' ? 'Invoice From' : (curentLangDocument === 'ar' ? 'فاتورة من' : 'חשבונית מ')}} </h4> -->
                                                            <div class="invo-addr-1">
                                                                <div style="font-weight: bold;">
                                                                    <!-- <strong>{{ curentLangDocument === 'en' ? 'Company Name' : (curentLangDocument === 'ar' ? 'اسم الشركة' : 'שם החברה')}} / </strong>  -->
                                                                    <strong>{{ invoiceInfoData?.company_name }}</strong>
                                                                </div>
                                                                
                                                                <div style="font-weight: 300;">
                                                                    <!-- <strong> {{ curentLangDocument === 'en' ? 'Company Address' : (curentLangDocument === 'ar' ? 'عنوان الشركة' : 'כתובת החברה')}} / </strong> -->
                                                                    <strong>{{ invoiceInfoData?.company_address_str }} -</strong>
                                                                    <strong>{{ invoiceInfoData?.company_Building_number }} -</strong>
            
                                                                    
                                                                    <strong>{{ invoiceInfoData?.company_address_city }}</strong>
                                                                </div>
                                                                
                                                              
                                                                <!-- <div style="font-weight: 400;">{{ invoiceInfoData?.company_address_zip_code }}</div> -->
                                                                <!-- <div style="font-weight: 300;">
                                                                    <strong>{{ curentLangDocument === 'en' ? 'Company Vat Number' : (curentLangDocument === 'ar' ? '  الرقم الضريبي للشركة' : 'מספר מס חברה')}} / </strong>
                                                                    <strong>{{ invoiceInfoData?.company_vat }}</strong>
                                                                
                                                                </div> -->
            
                                                                <div  style="font-weight: 300;"> 
                                                                    <strong>{{ curentLangDocument === 'en' ? 'Company Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : '     מס עוסק מורשה     ')}}  </strong> 
                                                               </div>
                                                               <div  style="font-weight: 300;"> 
                                                               <strong>{{ invoiceInfoData?.company_vat }}</strong>
                                                               </div>
                                                              
            
                                                              
                                                                
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="row  ">
                                                    <div class="col-sm-4"></div>
            
                                                    
            
                                                    <div class="invoice text-center   col-sm-4">
                                                        <!-- <h4 class="inv-title-1">{{ curentLangDocument === 'en' ? 'Invoice Details' : (curentLangDocument === 'ar' ? 'تفاصيل الفاتورة' : ' פרטי החשבונית  ')}}</h4> -->
            
                                                   
            
                                                        <div class="mb-1"*ngIf="invoiceInfoData?.document_number !== null">
                                                       <strong *ngIf="invoiceInfoData?.invoice_type == 305" style="font-weight: bold;" class="mx-1">
                                                        {{curentLangDocument === 'en' ? 'Tax Invoice Number' : (curentLangDocument === 'ar' ? 'فاتورة ضريبية رقم' : '  חשבונית מס מספר       ') }}
                                                      </strong> 
            
                                                      <strong *ngIf="invoiceInfoData?.invoice_type == 320" style="font-weight: bold;" class="mx-1">
                                                        {{curentLangDocument === 'en' ? 'Invoice With Receipt Number' : (curentLangDocument === 'ar' ? 'فاتورة مع  ايصال رقم' : 'חשבונית עם מספר קבלה') }}
                                                     </strong> 
            
                                                     <strong *ngIf="invoiceInfoData?.invoice_type == 332" style="font-weight: bold;" class="mx-1">
                                                        {{curentLangDocument === 'en' ? ' Financial Request Number' : (curentLangDocument === 'ar' ? 'مطالبة مالية رقم' : ' תביעה כספית מס  ') }}
                                                     </strong> 
            
                                                     <strong *ngIf="invoiceInfoData?.invoice_type == 400" style="font-weight: bold;" class="mx-1">
                                                        {{curentLangDocument === 'en' ? 'Receipt Number' : (curentLangDocument === 'ar' ? ' ايصال رقم' : ' מס קבלה  ') }}
                                                     </strong> 
            
                                                     <strong *ngIf="invoiceInfoData?.invoice_type == 330" style="font-weight: bold;" class="mx-1">
                                                        {{curentLangDocument === 'en' ? 'Reverce Invoice Number' : (curentLangDocument === 'ar' ? '  فاتورة عكسية رقم  ' : '      חשבונית זיכוי קבלה ') }}
                                                     </strong> 
            
                                                     <strong *ngIf="invoiceInfoData?.invoice_type == 100" style="font-weight: bold;" class="mx-1">
                                                      {{curentLang === 'en' ? '    order' : (curentLang === 'ar' ? 'طلبية    ' : '   לְהַזמִין   ') }}
                                                   </strong> 
            
                                                   <strong *ngIf="invoiceInfoData?.invoice_type == 200" style="font-weight: bold;" class="mx-1">
                                                      {{curentLang === 'en' ? '    mission' : (curentLang === 'ar' ? 'ارسالية    ' : '   תעודת משלוח   ') }}
                                                   </strong> 
            
                                                   <strong *ngIf="invoiceInfoData?.invoice_type == 000" style="font-weight: bold;" class="mx-1">
                                                      {{curentLang === 'en' ? '    Offer Price' : (curentLang === 'ar' ? 'عرض سعر     ' : ' מחיר הצעה') }}
                                                   </strong> 
            
                                                     <strong style="font-weight: 400; color: black;">{{invoiceInfoData?.document_number}}</strong>
            
            
                                                        
                                                    </div>
                                                    <div [hidden]="invoiceInfoData?.confirmation_number == null"  style="font-weight: 300;color: black;">
                                                        <strong style="font-weight: bold;" class="mx-1">{{ curentLangDocument === 'en' ? 'Confirmation Number' : (curentLangDocument === 'ar' ? ' رقم تخصيص' : ' מספר הקצאה  ')}} : </strong> 
                                                       <strong>{{ invoiceInfoData?.confirmation_number }}  </strong>
                                                   </div>
                                                       
                                                       
                                                    </div>
            
                                                    <div class="col-sm-4    text-end">
                                                        <!-- <h4 class="inv-title-1">{{ curentLangDocument === 'en' ? 'Date' : (curentLangDocument === 'ar' ? ' التاريخ  ' : ' תאריך  ')}}</h4> -->
                                                       
                                                             <div class="mb-0" style="font-weight: bold;">
                                                                {{ curentLangDocument === 'en' ? ' Date' : (curentLangDocument === 'ar' ? 'تاريخ ' : 'תאריך ')}}
                                                            </div> 
                                                            <div style="font-weight: 400; color: black;">{{ invoiceInfoData?.invoice_date| date:'dd/MM/yyyy' }}</div>
                                                    </div>
            
            
                                                </div>
                                            </div>
                                            <div class="order-summary" *ngIf="invoiceInfoData?.invoice_type !== 400" >
                                                <div class="table-responsive">
                                                    <table class="table invoice-table">
                                                        <thead class="bg-active" style="font-weight: bold;   ">
                                                            <tr>
                                                                <th
                                                                    class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Item Name' : (curentLangDocument === 'ar' ? 'اسم الصنف' : 'פריט  ')}}</th>
                                                                 <th
                                                                    class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Description' : (curentLangDocument === 'ar' ? 'الوصف' : 'תיאור')}}</th> 
                                                                <!-- <th
                                                                    class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Measure Unit' : (curentLangDocument === 'ar' ? 'وحدة القياس' : 'יחידת מידה')}}</th> -->
                                                                <th
                                                                    class="text-center" style="font-weight: bold;"> {{ curentLangDocument === 'en' ? 'Price' : (curentLangDocument === 'ar' ? 'السعر  ' : ' מחיר  ')}}</th>
                                                                <th
                                                                    class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Quantity' : (curentLangDocument === 'ar' ? 'الكمية' : 'כמות')}}</th>
                                                                <th
                                                                    class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Discount' : (curentLangDocument === 'ar' ? 'الخصم' : 'הנחה')}}</th>
                                                                <th class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع' : 'סה"כ')}}</th>
            
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor=" let item of invoiceInfoData?.lines">
                                                                <td class="text-center" style="font-weight: 400; color: black;" >{{item?.item_name}}</td>  
                                                                
                                                                <td  class="text-center  description-column"style="font-weight: 400; color: black;">{{item?.description}}</td>
            
                                                              <!--   <td class="text-center  description-column" style="font-weight: 400; color: black;">{{item?.measure_unit_description}}</td> -->
            
                                                                <td class="text-center" style="font-weight: 400; color: black;" >{{item?.price_per_unit | number:'1.2-2'}}</td>
            
                                                                <td class="text-center" style="font-weight: 400; color: black;" >{{item?.quantity}}</td>
            
                                                                <td class="text-center" style="font-weight: 400; color: black;" >{{item?.discount}}</td>
            
                                                                <td class="text-center" style="font-weight: 400; color: black;" >{{item?.sub_total | number:'1.2-2'}}</td>
            
                                                            </tr>
            
                                                        </tbody>
                                                    </table>
            
                                                  
            
                                                </div>
                                                <div class="small-table my-4">
                                                    <div class="row">
                                                        <div class="col-md-12  ">
                                                            <div class="d-flex justify-content-between" style="background-color: #F1F1F1;">
                                                                <div class="col-md-6  p-2 ">
                                                                    <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Amount Before Discount:' : (curentLangDocument === 'ar' ? 'المبلغ قبل الخصم' : '   מחיר לפני הנחה    ')}}</div>
                                                                    <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Discount:' : (curentLangDocument === 'ar' ? 'الخصم' : 'הנחה')}}</div>
                                                                    <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Payment Amount:' : (curentLangDocument === 'ar' ? '   المبلغ المدفوع    ' : '      סה"כ      ')}}</div>
                                                                    <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'VAT Amount:' : (curentLangDocument === 'ar' ? '       المبلغ الضريبي    ' : 'מע"מ')}}</div>
                                                                    <div style="width: 300px; font-weight: bold;" class="m-1"> {{ curentLangDocument === 'en' ? 'Payment Amount Including VAT:' : (curentLangDocument === 'ar' ? ' المبلغ المدفوع شامل الضريبة ' : 'סה"כ לשתלום')}}</div>
            
                                                                </div>
            
                                                             
            
                                                                <div class="col-md-6 text-end px-3 p-2">
                                                                    <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.amount_before_discount | number:'1.2-2'}}</div>
                                                                    <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.all_discount | number:'1.2-2'}}</div>
                                                                    <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.payment_amount | number:'1.2-2'}}</div>
                                                                    <div style="font-weight: 400; color: black;" class="my-1">({{invoiceInfoData?.vat_percentage}} %)   {{invoiceInfoData?.all_vat_amount | number:'1.2-2'}}</div>
                                                                    <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.payment_amount_including_vat | number:'1.2-2'}}</div>
            
                                                                </div>
                                                               
                                                             
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
            
                                            </div>
            
            
                                              <div class="order-summary  " *ngIf="invoiceInfoData?.invoice_type == 320 || invoiceInfoData?.invoice_type == 400">
            
                                                <div class="col-lg-12 " >
                                                    <ng-container  >
                                                        <div class="mt-4 d-flex justify-content-between">
                                                            <span style="font-weight: bold; " class="mx-2">{{ curentLangDocument === 'en' ? 'Payment Info' : (curentLangDocument === 'ar' ? 'بيانات الدفع  ' : ' נתוני תשלום')}} </span>
                                                            <!-- <span style="font-weight: 500;" class="mx-3">{{ invoiceInfoData.checks_amount | number:'1.0-0' }}</span> -->
                                                        </div>  
                                                
                                                        <div class="table-responsive ">
                                                            <table class="table invoice-table">
                                                                <thead>
                                                                
                                                                    <tr>
                                                                        <th
                                                                            class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Payment Method ' : (curentLangDocument === 'ar' ? 'طرق الدفع  ' : 'דרכי תשלום')}}
                                                                        </th>
                                                                        <!-- <th
                                                                            class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Ref' : (curentLangDocument === 'ar' ? 'مرجع  ' : 'רפ')}}</th> -->
                                                                        <!-- <th
                                                                            class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Bank' : (curentLangDocument === 'ar' ? 'بنك  ' : 'בַּנק')}}
                                                                        </th> -->
                                                                        <!-- <th
                                                                            class="text-center" style="font-weight: bold;" > {{ curentLangDocument === 'en' ? 'Branch' : (curentLangDocument === 'ar' ? 'فرع  ' : 'סניף')}}
                                                                        </th>
                                                                        
                                                                        <th
                                                                            class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Account' : (curentLangDocument === 'ar' ? 'حساب  ' : 'חֶשְׁבּוֹן')}}
                                                                        </th> -->
                                
                                                                        <th
                                                                            class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Details' : (curentLangDocument === 'ar' ? 'تفاصيل  ' : 'פרטים')}}</th>
                                                                            <th
                                                                            class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Date' : (curentLangDocument === 'ar' ? ' تاريخ  ' : ' תאריך')}}</th>
                                                                        <th class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع  ' : 'סכום')}}</th>
                                
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor=" let item of invoiceInfoData?.receiptsDetails">
                                                                        <td class="text-center" style="font-weight: 400; color: black;">
                                                                            <span *ngIf=" item?.payment_type == 1"> {{ curentLangDocument === 'en' ? 'Cash' : (curentLangDocument === 'ar' ? 'كاش' : 'מְזוּמָנִים')}}</span>
                                                                            <span *ngIf=" item?.payment_type == 2"> {{ curentLangDocument === 'en' ? 'Check' : (curentLangDocument === 'ar' ? 'شيك' : 'לִבדוֹק')}}</span>
                                                                            <span *ngIf=" item?.payment_type == 3"> {{ curentLangDocument === 'en' ? 'Bank' : (curentLangDocument === 'ar' ? 'بنك' : 'בַּנק')}}</span>
                                                                            <span *ngIf=" item?.payment_type == 4"> {{ curentLangDocument === 'en' ? 'Credit' : (curentLangDocument === 'ar' ? 'ائتمان' : 'אַשׁרַאי')}}</span>
                                                                        </td>
                                                                        <!-- <td class="text-center">{{ item.bank }}</td>
                                                                        <td class="text-center">{{ item.branch }}</td>
                                                                        <td class="text-center">{{ item.account }}</td>
                                                                         -->
                                
                                                                        <td class="text-center description-column" style="font-weight: 400; color: black;">{{ item.details }}</td>
                                                                        <td class="text-center" style="font-weight: 400; color: black;">{{ item.date | date: 'yyyy-MM-dd' }}</td>
                                                                        <td class="text-center" style="font-weight: 400; color: black;">{{ item.amount | number:'1.2-2'}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </ng-container>
                                                </div>
            
            
                          
            
                            <div class="small-table my-4" >
                                <div class="row">
                                    <div class="col-md-12 d-flex justify-content-between" >
                                        <div class="col-md-6 mt-5">
                                          <!-- <span>Weighted date: 05/05/24</span> -->
                                         </div>
                                         <div class="col-md-6">
                                            <div class="row d-flex justify-content-between mx-1" style="background-color: #F1F1F1;">
                                                <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                                                    <span class="py-1"> {{ curentLangDocument === 'en' ? 'Deduction at source' : (curentLangDocument === 'ar' ? 'الخصم من المصدر  ' : '  ניכוי במקור')}} :  </span>
                                                </div>
            
                                                <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                                                    <span class="mx-1">{{invoiceInfoData.deduction_from_source | number:'1.0-0'}}</span>
                                                </div>
            
                                                <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                                                    <span>{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع' : 'סה"כ')}}  :  </span>
                                                </div>
            
                                                <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                                                    <span class="mx-1">{{ invoiceInfoData.total_amount | number:'1.2-2' }}</span>
            
                                                </div>
            
            
                                            </div>
                                            
                                         </div>
            
                                         <div class="col-md-6">
            
                                         </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                            
                            <!-- <div class=" d-flex justify-content-between"> 
            
                                <button type="button"  hidden
                                class="btn   m-2" 
                               style="background-color: #141414; color: #fff;"
                                
                                > 
                                 {{ curentLangDocument === 'en' ? 'Update' : (curentLangDocument === 'ar' ? '     تحديث ' : '     עדכן  ') }}
                                 <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                             </button>
                        
                               
            
                                    <img src="../../../../assets/images/logo/thi.png"  class="  m-2 "  alt="">
                              
                                  
                                
                        
                              </div> -->
                            
                        </div>
                                                
                                                
                                            </div>
            
                                        </div>
            
                                    </div>
                                </div>
                            </div>
                        </div>
            
            
                        
            
            
            
                    </div>
            
                    
            
                </div>
            
            
            
            
                <!--back-->
                <div class="modal-footer my-2">
                <div class=" d-flex justify-content-between"> 
            
                    <button type="button"  hidden
                    class="btn   m-2" 
                   style="background-color: #141414; color: #fff;"
                    
                    > 
                     {{ curentLangDocument === 'en' ? 'Update' : (curentLangDocument === 'ar' ? '     تحديث ' : '     עדכן  ') }}
                     <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                 </button>
            
                <!--    <a routerLink="/dashboard/company-documents/{{invoiceInfoData?.company_uuid}}" *ngIf="user_type == 300 || user_type == 301">
                      <button class="btn btn-danger m-2 " >
                        {{ curentLangDocument === 'en' ? 'Back' : (curentLangDocument === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                        <i class="fa fa-rotate-left mx-1"></i>
                      </button>
                    </a>
            -->
                    <a routerLink="/dashboard/documents/{{invoiceInfoData?.company_uuid}}" *ngIf="user_type == 200 || user_type == 201">
                        <button class="btn btn-danger m-2 " >
                          {{ curentLangDocument === 'en' ? 'Back' : (curentLangDocument === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                          <i class="fa fa-rotate-left mx-1"></i>
                        </button>
                      </a>
            
                      <button type="button" class="btn btn-sm mt-2 btn-danger" (click)="modal.close('Close click')">
                        {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
                        <i class="fa fa-rotate-left mx-1"></i>
                      </button>
            
                  </div>
                </div>
                </div>
              </div>
            </ng-template>






               <!--procedures-->

               <ng-template #dataProcedures let-modal>
                <div class="modal-header">
                <h4 class="modal-title"></h4>
                <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body text-center">
                  <h5 class="text-center"> 
                    {{curentLang === 'en' ? 'Please select the type of procedure that suits you.' : (curentLang === 'ar' ? '  من فضلك قم باختيار نوع الاجراء المناسب لك' : '  אנא בחר את סוג ההליך המתאים לך') }}
                  </h5>
                </div>
              
                <div class="modal-footer">

                  <div class="container">
                    <div class="row    ">

                      <div class="table-responsive">
                        <table class="table  table-proced text-center table-hover  " >
                          <thead>
                            <tr>
                              <th scope="col" style="font-weight: 700; font-size: 18px;">{{ curentLang === 'en' ? 'Procedures' : (curentLang === 'ar' ? 'الاجراءات' : '  נהלים ') }}</th>
                            </tr>
                          </thead>
            
                          <tbody>
                            <tr>
                             <td>
                              <div class="m-auto my-2 action">
                                <button 
                                  type="button" 
                                  class="btn btn-sm w-75" 
                                  style="  color: #fff;" 
                              
                                  (click)=" openProceduresModal(dataProceduresCancleInvoice)"
                                >
                                  {{ curentLang === 'en' ? 'Invoice Cancle' : (curentLang === 'ar' ? 'الغاء الفاتورة' : 'ביטול חשבונית   ') }}
                                </button>
                              </div>
                              </td>
                              </tr>
                              <!---->
                              <tr>
                              <td>
                                <div class="m-auto  my-2 action">
                                  <button 
                                    type="button" 
                                    class="btn btn-sm w-75" 
                                    style="  color: #fff;" 
                                    
                                    (click)=" openProceduresModal(dataProceduresContinueInvoice)"
                                  >
                                    {{ curentLang === 'en' ? 'Issuing an Invoice Without an Allocation Number' : (curentLang === 'ar' ? 'إصدار فاتورة بدون رقم تخصيص ' : 'הפקה ללא מספר הקצאה') }}
                                  </button>
                                </div>
                                </td>
                                </tr>
                                <!---->

                                <tr>
                                <td>
                                  <div class="m-auto my-2 action">
                                    <button 
                                      type="button" 
                                      class="btn btn-sm w-75" 
                                      style=" color: #fff;"
                                       (click)=" openProceduresModal(dataProceduresInvoiceDecisionApiFurtherObjection)"
                                    >
                                      {{ curentLang === 'en' ? 'Decision Further Objection' : (curentLang === 'ar' ? 'طلب جلسة استماع' : 'בקשת שימוע    ') }}
                                    </button>
                                  </div>
                                  </td>
                                </tr>
                                  <!---->
                                <tr>
                                  <td>
                                    <div class="m-auto my-2 action">
                                      <button 
                                        type="button" 
                                        class="btn btn-sm w-75" 
                                        style="  color: #fff;" 
                                         (click)=" openProceduresModal(dataProceduresInvoiceAfterHearingApproval)"
                                      >
                                        {{ curentLang === 'en' ? 'Request allocation number' : (curentLang === 'ar' ? 'طلب رقم تخصيص' : 'בקשת מספר הקצאה ') }}
                                      </button>
                                    </div>
                                    </td>
                                </tr>
                                    <!---->
 
                                    <tr> 
                                    <td>
                                      <div class="m-auto my-2 action">
                                        <button 
                                          type="button" 
                                          class="btn btn-sm w-75" 
                                          style="  color: #fff;" 
                                          (click)=" openProceduresModal(dataProceduresInvoiceReversingTheCharge)"
                                        >
                                          {{ curentLang === 'en' ? 'Reversing the invoice to the customer (Invoice without a transaction)' : (curentLang === 'ar' ? '  عكس الفاتورة على الزبون (فاتورة بدون ماعام)  ' : '   היפוך חיוב (חשבונית ללא מע״מ )  ') }}
                                        </button>
                                      </div>
                                      </td>
                                      <!---->
                            </tr>
                      
                          </tbody>
                        </table>
             
                      </div>
                        
                    </div>
                  </div>

                </div>


              </ng-template>



    <!--procedures Cancle Invoice-->
  
    <ng-template #dataProceduresCancleInvoice let-modal>
      <div class="modal-header">
      <h4 class="modal-title"></h4>
      <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
      </div>
      <div class="modal-body text-center">
        <h5 class="text-center "> 
          {{curentLang === 'en' ? 'The program will cancel the invoice. Are you sure?' : (curentLang === 'ar' ? 'سيقوم البرنامج بإلغاء الفاتورة، هل انت متاكد ' : ' האם אתה בטוח שברוצנך לבטל את החשבונית ') }}
        </h5>
      </div>
    
      <div class="modal-footer">

        <div class="container">
          <div class="row d-flex justify-content-center align-items-center" > <!-- تعديل هنا -->
            <div class="text-center"> <!-- إضافة نص مركزي -->
              <button 
                type="button" 
                class="btn btn-sm mx-1" 
                style="background-color: #141414; color: #fff;" 
                (click)="InvoiceDecisionApiCancel()"
              >
                {{ curentLang === 'en' ? 'Invoice Cancel' : (curentLang === 'ar' ? 'الغاء الفاتورة' : ' ביטול חשבונית      ') }}
              </button>

              <button type="button" class="btn btn-sm m-auto mx-1 btn-danger" (click)="modal.close('Close click')">
                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                <i class="fa fa-rotate-left mx-1"></i>
            </button>
            </div>
          </div>
        </div>
        

      </div>


    </ng-template>


     <!--procedures Continue Invoice-->
  
     <ng-template #dataProceduresContinueInvoice let-modal>
      <div class="modal-header">
      <h4 class="modal-title"></h4>
      <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
      </div>
      <div class="modal-body text-center">
        <h5 class="text-center "> 
          {{curentLang === 'en' ? 'Issuing an invoice without an allocation number will prevent the customer from reclaiming the tax. A hearing session can be requested to obtain an allocation number even after issuing the invoice. Are you sure?' : (curentLang === 'ar' ? ' إصدار فاتورة بدون رقم تخصيص ولن يستطيع الزبون استرجاع الضريبة منها، يمكن طلب جلسة استماع للحصول على رقم تخصيص حتى بعد إصدار الفاتورة هل انت متاكد؟' : 'הפקת חשבונית ללא מספר הקצאה לא יאפשר ללקוח לקזז מס תשומות, יש אפשרות לבקש שימוע גם לאחר הפקת החשבונית. האם אתה בטוח?         ') }}
        </h5>
      </div>
    
      <div class="modal-footer">

        <div class="container">
          <div class="row d-flex justify-content-center align-items-center" > <!-- تعديل هنا -->
            <div class="text-center"> <!-- إضافة نص مركزي -->
              <button 
                type="button" 
                class="btn btn-sm mx-1" 
                style="background-color: #141414; color: #fff;" 
                (click)="InvoiceDecisionApiContinue()"
              >
              {{ curentLang === 'en' ? 'Issuing an Invoice Without an Allocation Number' : (curentLang === 'ar' ? 'إصدار فاتورة بدون رقم تخصيص ' : 'הפקה ללא מספר הקצאה') }}
            </button>

            <button type="button" class="btn btn-sm m-auto mx-1 btn-danger" (click)="modal.close('Close click')">
              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
              <i class="fa fa-rotate-left mx-1"></i>
          </button>
            </div>
          </div>
        </div>
        

      </div>


    </ng-template>

              

      <!--procedures Invoice Decision Api Further Objection Invoice-->
  
      <ng-template #dataProceduresInvoiceDecisionApiFurtherObjection let-modal>
        <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body text-center">
          <h5 class="text-center  "> 
            {{curentLang === 'en' ? 'We will register your request with the tax authority to schedule a hearing for this invoice. Do you want to proceed with the registration? If you register, we will send you a link to book an appointment for the hearing.' : (curentLang === 'ar' ? ' سوف نسجل عند سلطة الضرايب برغبتك طلب جلسة استماع على هذه الفاتورة، هل تريد التسجيل؟ إذا قمت بالتسجيل سوف نرسل لك رابط لحجز موعد لجلسة الاستماع  ' : 'המערכת תבקש שימוע עבור חשבונית זו, האם להמשיך? אם בחרת להמשיך תקבל בסוף התהליך קישור לקביעת תור לשימוע') }}
          </h5>
        </div>
      
        <div class="modal-footer">
  
          <div class="container">
            <div class="row d-flex justify-content-center align-items-center" > <!-- تعديل هنا -->
              <div class="text-center"> <!-- إضافة نص مركزي -->
                <button 
                  type="button" 
                  class="btn btn-sm mx-1" 
                  style="background-color: #141414; color: #fff;"
                  (click)="InvoiceDecisionApiFurtherObjection()"
                  >
                    {{ curentLang === 'en' ? 'Decision Further Objection' : (curentLang === 'ar' ? 'طلب جلسة استماع' : 'בקשת שימוע  ') }}
              </button>

              <button type="button" class="btn btn-sm m-auto mx-1 btn-danger" (click)="modal.close('Close click')">
                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                <i class="fa fa-rotate-left mx-1"></i>
            </button>
              </div>
            </div>
          </div>
          
  
        </div>
  
  
      </ng-template>
  

        <!--procedures Invoice After Hearing Approval-->
  
        <ng-template #dataProceduresInvoiceAfterHearingApproval let-modal>
          <div class="modal-header">
          <h4 class="modal-title"></h4>
          <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
          </div>
          <div class="modal-body text-center">
            <h5 class="text-center "> 
              {{curentLang === 'en' ? 'This selection will result in sending a request for an invoice allocation number.' : (curentLang === 'ar' ? 'هذا الاختيار سيؤدي الي ارسال طلب رقم تخصيص للفاتورة ' : 'בחירה זו תגרום לשליחת בקשה למספר הקצאה עבור החשבונית') }}
            </h5>
          </div>
        
          <div class="modal-footer">
    
            <div class="container">
              <div class="row d-flex justify-content-center align-items-center" > <!-- تعديل هنا -->
                <div class="text-center"> <!-- إضافة نص مركزي -->
                  <button 
                    type="button" 
                    class="btn btn-sm mx-1" 
                    style="background-color: #141414; color: #fff;" 
                    (click)="InvoiceAfterHearingApproval()"
                    >
                    {{ curentLang === 'en' ? 'Request allocation number' : (curentLang === 'ar' ? 'طلب رقم تخصيص' : 'בקשת מספר הקצאה    ') }}

                </button>

                <button type="button" class="btn btn-sm m-auto mx-1 btn-danger" (click)="modal.close('Close click')">
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
              </button>
                </div>
              </div>
            </div>
            
    
          </div>
    
    
        </ng-template>

         <!--procedures Invoice Reversing The Charge -->
  
         <ng-template #dataProceduresInvoiceReversingTheCharge let-modal>
          <div class="modal-header">
          <h4 class="modal-title"></h4>
          <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
          </div>
          <div class="modal-body text-center">
            <h5 class="text-center"> 
              {{curentLang === 'en' ? 'Reversing the invoice to the customer involves issuing an invoice with zero-added tax. It is recommended to coordinate with the customer first. Do you want to proceed?' : (curentLang === 'ar' ? '  عكس الفاتورة على الزبون، هو إصدار فاتورة بضريبة مضافة صفرية، يفضل التنسيق مع الزبون اولا. هل تريد الاستمرار؟   ' : '  היפוך חיוב, יגרום להפקת חשבונית ללא מע״מ, עדיף לתאם עם הלקוח לפני היפוך חיוב, האם להמשיך?  ') }}
            </h5>
          </div>
        
          <div class="modal-footer">
    
            <div class="container">
              <div class="row d-flex justify-content-center align-items-center" > <!-- تعديل هنا -->
                <div class="text-center"> <!-- إضافة نص مركزي -->
                  <button 
                    type="button" 
                    class="btn btn-sm mx-1" 
                    style="background-color: #141414; color: #fff;" 
                    (click)="InvoiceReversingTheCharge()"
                    >
                    {{ curentLang === 'en' ? 'Reversing the Invoice to the Customer (Invoice Without a Transaction)' : (curentLang === 'ar' ? '  عكس الفاتورة على الزبون (فاتورة بدون ماعام)  ' : ' היפוך חיוב (חשבונית ללא מע״מ )    ') }}

                </button>

                <button type="button" class="btn btn-sm m-auto mx-1  btn-danger" (click)="modal.close('Close click')">
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
              </button>
                </div>
              </div>
            </div>
            
    
          </div>
    
    
        </ng-template>